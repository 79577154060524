<template>
  <v-container
    class="forbidden-page fill-height"
    tag="section"
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col cols="auto">
        <h1 class="font-weight-black">
          {{ $t('dialog.you-dont-have-the-rights') }}
        </h1>

        <div class="display-3 mb-5 mt-10" />

        <v-btn
          depressed
          to="/app"
        >
          {{ $t('back-to-the-app') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Forbidden',
  }
</script>
